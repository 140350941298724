export const Shiyu6_1 = [
  {
    char_one: 'jane-doe',
    char_two: 'Burnice',
    char_three: 'Caesar',
    app_rate: 10.86,
    rank: 1,
    avg_round: 2.98,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Burnice',
    char_three: 'Lucy',
    app_rate: 10.17,
    rank: 2,
    avg_round: 2.98,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Burnice',
    char_three: 'Lucy',
    app_rate: 8.38,
    rank: 3,
    avg_round: 2.96,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 6.44,
    rank: 4,
    avg_round: 2.91,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 6.4,
    rank: 5,
    avg_round: 2.95,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Seth',
    char_three: 'Caesar',
    app_rate: 3.47,
    rank: 6,
    avg_round: 2.96,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Burnice',
    char_three: 'Seth',
    app_rate: 3.45,
    rank: 7,
    avg_round: 2.98,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Seth',
    char_three: 'Lucy',
    app_rate: 3.39,
    rank: 8,
    avg_round: 2.98,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Burnice',
    char_three: 'Caesar',
    app_rate: 3.37,
    rank: 9,
    avg_round: 2.97,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 3.13,
    rank: 10,
    avg_round: 2.96,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 2.58,
    rank: 11,
    avg_round: 2.93,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Burnice',
    char_three: 'Lucy',
    app_rate: 2.4,
    rank: 12,
    avg_round: 2.91,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 2.28,
    rank: 13,
    avg_round: 2.88,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Soukaku',
    char_three: 'Caesar',
    app_rate: 1.96,
    rank: 14,
    avg_round: 2.97,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 1.9,
    rank: 15,
    avg_round: 2.93,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Burnice',
    char_three: 'Caesar',
    app_rate: 1.53,
    rank: 16,
    avg_round: 2.95,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Caesar',
    app_rate: 1.49,
    rank: 17,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Seth',
    char_three: 'Rina',
    app_rate: 1.21,
    rank: 18,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 1.11,
    rank: 19,
    avg_round: 2.96,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Koleda',
    char_three: 'Soukaku',
    app_rate: 0.97,
    rank: 20,
    avg_round: 2.92,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.95,
    rank: 21,
    avg_round: 2.95,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Ben',
    char_three: 'Lucy',
    app_rate: 0.89,
    rank: 22,
    avg_round: 2.96,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'nicole-demara',
    char_three: 'Caesar',
    app_rate: 0.79,
    rank: 23,
    avg_round: 2.94,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.75,
    rank: 24,
    avg_round: 2.89,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.71,
    rank: 25,
    avg_round: 2.94,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Qingyi',
    char_three: 'Soukaku',
    app_rate: 0.67,
    rank: 26,
    avg_round: 2.85,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Koleda',
    char_three: 'nicole-demara',
    app_rate: 0.5,
    rank: 27,
    avg_round: 2.91,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'anby-demara',
    char_three: 'Soukaku',
    app_rate: 0.5,
    rank: 27,
    avg_round: 2.9,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Burnice',
    char_three: 'Lucy',
    app_rate: 0.48,
    rank: 29,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Burnice',
    char_three: 'Lucy',
    app_rate: 0.42,
    rank: 30,
    avg_round: 2.93,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Piper',
    char_three: 'Lucy',
    app_rate: 0.4,
    rank: 31,
    avg_round: 2.65,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.38,
    rank: 32,
    avg_round: 2.74,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Koleda',
    char_three: 'Caesar',
    app_rate: 0.38,
    rank: 32,
    avg_round: 2.94,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Seth',
    char_three: 'nicole-demara',
    app_rate: 0.36,
    rank: 34,
    avg_round: 2.92,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.36,
    rank: 34,
    avg_round: 2.81,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Koleda',
    char_three: 'Ben',
    app_rate: 0.36,
    rank: 34,
    avg_round: 2.89,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'Lucy',
    app_rate: 0.36,
    rank: 34,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Burnice',
    char_three: 'Caesar',
    app_rate: 0.32,
    rank: 38,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'jane-doe',
    char_three: 'Caesar',
    app_rate: 0.32,
    rank: 38,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'Seth',
    app_rate: 0.32,
    rank: 38,
    avg_round: 2.93,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.3,
    rank: 41,
    avg_round: 2.86,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Burnice',
    char_three: 'Rina',
    app_rate: 0.28,
    rank: 42,
    avg_round: 2.93,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.26,
    rank: 43,
    avg_round: 2.77,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Burnice',
    char_three: 'Koleda',
    app_rate: 0.24,
    rank: 44,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'Ben',
    char_three: 'Lucy',
    app_rate: 0.24,
    rank: 44,
    avg_round: 2.91,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'anby-demara',
    char_three: 'Lucy',
    app_rate: 0.24,
    rank: 44,
    avg_round: 2.92,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'jane-doe',
    char_three: 'Lucy',
    app_rate: 0.22,
    rank: 47,
    avg_round: 2.7,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.22,
    rank: 47,
    avg_round: 2.9,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'Seth',
    app_rate: 0.2,
    rank: 49,
    avg_round: 2.75,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Burnice',
    char_three: 'Qingyi',
    app_rate: 0.18,
    rank: 50,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'jane-doe',
    char_three: 'Seth',
    app_rate: 0.18,
    rank: 50,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'anby-demara',
    char_three: 'Seth',
    app_rate: 0.16,
    rank: 52,
    avg_round: 2.8,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Burnice',
    char_three: 'Koleda',
    app_rate: 0.16,
    rank: 52,
    avg_round: 2.75,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'nicole-demara',
    char_three: 'Caesar',
    app_rate: 0.16,
    rank: 52,
    avg_round: 2.62,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.16,
    rank: 52,
    avg_round: 2.86,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Rina',
    app_rate: 0.16,
    rank: 52,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Rina',
    char_three: 'Caesar',
    app_rate: 0.14,
    rank: 57,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Lucy',
    app_rate: 0.14,
    rank: 57,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Ben',
    char_three: 'Lucy',
    app_rate: 0.14,
    rank: 57,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'nicole-demara',
    char_three: 'Caesar',
    app_rate: 0.14,
    rank: 57,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Burnice',
    char_three: 'Rina',
    app_rate: 0.12,
    rank: 61,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Burnice',
    char_three: 'Lucy',
    app_rate: 0.12,
    rank: 61,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.12,
    rank: 61,
    avg_round: 2.75,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'nicole-demara',
    app_rate: 0.12,
    rank: 61,
    avg_round: 2.33,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'nicole-demara',
    char_three: 'Lucy',
    app_rate: 0.12,
    rank: 61,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.12,
    rank: 61,
    avg_round: 2.8,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Burnice',
    char_three: 'Caesar',
    app_rate: 0.12,
    rank: 61,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Burnice',
    char_three: 'Ben',
    app_rate: 0.12,
    rank: 61,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Burnice',
    char_three: 'Caesar',
    app_rate: 0.1,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Koleda',
    char_two: 'Ben',
    char_three: 'Lucy',
    app_rate: 0.1,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Koleda',
    char_three: 'nicole-demara',
    app_rate: 0.1,
    rank: 69,
    avg_round: 2.6,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Koleda',
    char_three: 'Seth',
    app_rate: 0.1,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'Koleda',
    char_three: 'Caesar',
    app_rate: 0.1,
    rank: 69,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.1,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Burnice',
    char_three: 'Rina',
    app_rate: 0.1,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Burnice',
    char_three: 'Soukaku',
    app_rate: 0.1,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Burnice',
    char_three: 'Caesar',
    app_rate: 0.1,
    rank: 69,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Burnice',
    char_three: 'Koleda',
    app_rate: 0.1,
    rank: 69,
    avg_round: 2.8,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'Caesar',
    app_rate: 0.08,
    rank: 79,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Piper',
    char_three: 'Caesar',
    app_rate: 0.08,
    rank: 79,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 0.08,
    rank: 79,
    avg_round: 2.75,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Lycaon',
    char_three: 'Lucy',
    app_rate: 0.08,
    rank: 79,
    avg_round: 2.75,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.08,
    rank: 79,
    avg_round: 2.75,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Soukaku',
    char_three: 'Rina',
    app_rate: 0.08,
    rank: 79,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.08,
    rank: 79,
    avg_round: 2.75,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'Caesar',
    app_rate: 0.08,
    rank: 79,
    avg_round: 2.33,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Qingyi',
    char_three: 'Caesar',
    app_rate: 0.08,
    rank: 79,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'jane-doe',
    char_three: 'Burnice',
    app_rate: 0.08,
    rank: 79,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Burnice',
    char_three: 'Qingyi',
    app_rate: 0.08,
    rank: 79,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Rina',
    char_three: 'Caesar',
    app_rate: 0.08,
    rank: 79,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.08,
    rank: 79,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Burnice',
    char_three: 'Caesar',
    app_rate: 0.08,
    rank: 79,
    avg_round: 2.75,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.08,
    rank: 79,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Burnice',
    char_three: 'Seth',
    app_rate: 0.08,
    rank: 79,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Burnice',
    char_three: 'Seth',
    app_rate: 0.06,
    rank: 95,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 95,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Soukaku',
    char_three: 'Lucy',
    app_rate: 0.06,
    rank: 95,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Soukaku',
    char_three: 'Seth',
    app_rate: 0.06,
    rank: 95,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 0.06,
    rank: 95,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Burnice',
    char_three: 'Lucy',
    app_rate: 0.06,
    rank: 95,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 95,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'jane-doe',
    char_three: 'Seth',
    app_rate: 0.06,
    rank: 95,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'Burnice',
    app_rate: 0.06,
    rank: 95,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Burnice',
    char_three: 'Lycaon',
    app_rate: 0.06,
    rank: 95,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.06,
    rank: 95,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'Seth',
    char_three: 'Lucy',
    app_rate: 0.06,
    rank: 95,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.06,
    rank: 95,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Piper',
    char_three: 'Lucy',
    app_rate: 0.06,
    rank: 95,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'jane-doe',
    char_three: 'Lucy',
    app_rate: 0.06,
    rank: 95,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Rina',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 110,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'Rina',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 110,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'Qingyi',
    app_rate: 0.04,
    rank: 110,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 110,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Rina',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 110,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Lycaon',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 110,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Ben',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 110,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Burnice',
    char_three: 'Ben',
    app_rate: 0.04,
    rank: 110,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'jane-doe',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 110,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Burnice',
    char_three: 'Qingyi',
    app_rate: 0.04,
    rank: 110,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'Rina',
    app_rate: 0.04,
    rank: 110,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Piper',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 110,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 110,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'anby-demara',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 110,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'nicole-demara',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 110,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'Qingyi',
    app_rate: 0.04,
    rank: 110,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Burnice',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 110,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.04,
    rank: 110,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'anby-demara',
    app_rate: 0.04,
    rank: 110,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.04,
    rank: 110,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Soukaku',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 110,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'Qingyi',
    app_rate: 0.04,
    rank: 110,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Koleda',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 110,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Ben',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 110,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Koleda',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 110,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'Rina',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 110,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Ben',
    char_three: 'Soukaku',
    app_rate: 0.04,
    rank: 110,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Ben',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 110,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Koleda',
    char_three: 'Rina',
    app_rate: 0.04,
    rank: 110,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'nicole-demara',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 110,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'jane-doe',
    char_three: 'Burnice',
    app_rate: 0.04,
    rank: 110,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Burnice',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 110,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'anby-demara',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 110,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Ellen',
    char_three: 'Soukaku',
    app_rate: 0.04,
    rank: 110,
    avg_round: 1.5,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Koleda',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 110,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'jane-doe',
    char_three: 'Koleda',
    app_rate: 0.04,
    rank: 110,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Rina',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 110,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Ben',
    char_three: 'Lucy',
    app_rate: 0.02,
    rank: 147,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Ben',
    char_three: 'Lucy',
    app_rate: 0.02,
    rank: 147,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'grace-howard',
    char_three: 'Soukaku',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'Lycaon',
    char_three: 'Lucy',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'grace-howard',
    char_three: 'Burnice',
    app_rate: 0.02,
    rank: 147,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Koleda',
    char_three: 'Ben',
    app_rate: 0.02,
    rank: 147,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'anby-demara',
    char_three: 'Caesar',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Lycaon',
    char_three: 'Rina',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Koleda',
    char_three: 'Caesar',
    app_rate: 0.02,
    rank: 147,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Burnice',
    char_three: 'Caesar',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Burnice',
    char_three: 'Koleda',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'jane-doe',
    char_three: 'Burnice',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'Soukaku',
    char_three: 'Lucy',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ben',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'Qingyi',
    char_three: 'Caesar',
    app_rate: 0.02,
    rank: 147,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Soukaku',
    char_two: 'nicole-demara',
    char_three: 'Lucy',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'anby-demara',
    char_three: 'Seth',
    app_rate: 0.02,
    rank: 147,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'jane-doe',
    char_three: 'Seth',
    app_rate: 0.02,
    rank: 147,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'jane-doe',
    char_three: 'Burnice',
    app_rate: 0.02,
    rank: 147,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Piper',
    char_three: 'Caesar',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Burnice',
    char_three: 'nicole-demara',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Soukaku',
    char_three: 'Lucy',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Ben',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'jane-doe',
    char_three: 'Seth',
    app_rate: 0.02,
    rank: 147,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Ben',
    char_two: 'Soukaku',
    char_three: 'Lucy',
    app_rate: 0.02,
    rank: 147,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'Koleda',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'nicole-demara',
    char_three: 'Lucy',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Soukaku',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.02,
    rank: 147,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'Ben',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'jane-doe',
    char_three: 'Caesar',
    app_rate: 0.02,
    rank: 147,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Koleda',
    char_three: 'Ben',
    app_rate: 0.02,
    rank: 147,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'anby-demara',
    char_three: 'Caesar',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Rina',
    char_three: 'Lucy',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Lycaon',
    char_three: 'Rina',
    app_rate: 0.02,
    rank: 147,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Burnice',
    char_three: 'Caesar',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Qingyi',
    char_three: 'Lycaon',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Burnice',
    char_three: 'anby-demara',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Koleda',
    char_three: 'Rina',
    app_rate: 0.02,
    rank: 147,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'grace-howard',
    char_three: 'Koleda',
    app_rate: 0.02,
    rank: 147,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Koleda',
    char_three: 'Rina',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'nicole-demara',
    char_three: 'Lucy',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Piper',
    char_three: 'Lucy',
    app_rate: 0.02,
    rank: 147,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Rina',
    char_three: 'Caesar',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'anby-demara',
    char_three: 'Lucy',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Piper',
    char_three: 'Soukaku',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'nicole-demara',
    char_three: 'Lucy',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Rina',
    char_three: 'Caesar',
    app_rate: 0.02,
    rank: 147,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Seth',
    char_three: 'Lucy',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'Koleda',
    char_three: 'Ben',
    app_rate: 0.02,
    rank: 147,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'Burnice',
    app_rate: 0.02,
    rank: 147,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'Lycaon',
    char_three: 'Caesar',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Koleda',
    char_three: 'Soukaku',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Lycaon',
    char_two: 'Soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Lycaon',
    char_three: 'Ben',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'jane-doe',
    char_three: 'nicole-demara',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'Rina',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Soukaku',
    char_three: 'Seth',
    app_rate: 0.02,
    rank: 147,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Rina',
    char_three: 'nicole-demara',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'Soukaku',
    char_three: 'Caesar',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'jane-doe',
    char_three: 'Burnice',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Corin',
    char_three: 'Soukaku',
    app_rate: 0.02,
    rank: 147,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'nicole-demara',
    char_three: 'Caesar',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Ellen',
    char_three: 'Lycaon',
    app_rate: 0.02,
    rank: 147,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'jane-doe',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'Ben',
    char_three: 'Caesar',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'jane-doe',
    char_three: 'Burnice',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'nicole-demara',
    char_three: 'Caesar',
    app_rate: 0.02,
    rank: 147,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Piper',
    char_three: 'Lucy',
    app_rate: 0.02,
    rank: 147,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Burnice',
    char_three: 'Lycaon',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Burnice',
    char_three: 'Seth',
    app_rate: 0.02,
    rank: 147,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Ben',
    char_two: 'nicole-demara',
    char_three: 'Lucy',
    app_rate: 0.02,
    rank: 147,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'jane-doe',
    char_three: 'Seth',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Piper',
    char_three: 'Koleda',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Lycaon',
    char_two: 'Soukaku',
    char_three: 'Rina',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'jane-doe',
    char_three: 'Koleda',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'jane-doe',
    char_three: 'Soukaku',
    app_rate: 0.02,
    rank: 147,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Ellen',
    char_three: 'Koleda',
    app_rate: 0.02,
    rank: 147,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'jane-doe',
    char_three: 'anby-demara',
    app_rate: 0.02,
    rank: 147,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'grace-howard',
    char_three: 'Qingyi',
    app_rate: 0.02,
    rank: 147,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'nicole-demara',
    app_rate: 0.02,
    rank: 147,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'jane-doe',
    char_three: 'Koleda',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Koleda',
    char_three: 'Rina',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'jane-doe',
    char_three: 'nicole-demara',
    app_rate: 0.02,
    rank: 147,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Corin',
    char_three: 'nicole-demara',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Lycaon',
    char_three: 'Seth',
    app_rate: 0.02,
    rank: 147,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Piper',
    char_three: 'Burnice',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Soukaku',
    char_three: 'Caesar',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'jane-doe',
    char_three: 'Qingyi',
    app_rate: 0.02,
    rank: 147,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'jane-doe',
    char_three: 'grace-howard',
    app_rate: 0.02,
    rank: 147,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Lycaon',
    char_three: 'Ben',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'anby-demara',
    char_three: 'Ben',
    app_rate: 0.02,
    rank: 147,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Burnice',
    char_three: 'nicole-demara',
    app_rate: 0.02,
    rank: 147,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Burnice',
    char_three: 'Soukaku',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Koleda',
    char_three: 'nicole-demara',
    app_rate: 0.02,
    rank: 147,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'jane-doe',
    char_three: 'Lycaon',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Burnice',
    char_three: 'nicole-demara',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'jane-doe',
    char_three: 'Caesar',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Nekomata',
    char_three: 'nicole-demara',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'Koleda',
    char_three: 'Soukaku',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'grace-howard',
    char_three: 'Koleda',
    app_rate: 0.02,
    rank: 147,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Ellen',
    char_three: 'Caesar',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'grace-howard',
    char_three: 'Rina',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Rina',
    char_three: 'Lucy',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'Lycaon',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'billy-kid',
    char_three: 'Piper',
    app_rate: 0.02,
    rank: 147,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'jane-doe',
    char_three: 'Lucy',
    app_rate: 0.02,
    rank: 147,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Koleda',
    char_three: 'Ben',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Corin',
    char_three: 'Burnice',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Koleda',
    char_two: 'Ben',
    char_three: 'Caesar',
    app_rate: 0.02,
    rank: 147,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Koleda',
    char_three: 'Soukaku',
    app_rate: 0.02,
    rank: 147,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Seth',
    char_three: 'nicole-demara',
    app_rate: 0.02,
    rank: 147,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Koleda',
    char_three: 'Ben',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Burnice',
    char_three: 'Ben',
    app_rate: 0.02,
    rank: 147,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Burnice',
    char_three: 'Qingyi',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Lycaon',
    char_two: 'Ben',
    char_three: 'Lucy',
    app_rate: 0.02,
    rank: 147,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'anby-demara',
    char_three: 'Ben',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'Caesar',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'jane-doe',
    char_three: 'grace-howard',
    app_rate: 0.02,
    rank: 147,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Qingyi',
    char_three: 'Caesar',
    app_rate: 0.02,
    rank: 147,
    avg_round: 3,
    star_num: '4'
  }
];

export const Shiyu7_2 = [
  {
    char_one: 'zhu-yuan',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 27.61,
    rank: 1,
    avg_round: 2.96,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 13.91,
    rank: 2,
    avg_round: 2.8,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Qingyi',
    char_three: 'Soukaku',
    app_rate: 5.6,
    rank: 3,
    avg_round: 2.82,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 5.52,
    rank: 4,
    avg_round: 2.9,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Soukaku',
    char_three: 'Caesar',
    app_rate: 3.34,
    rank: 5,
    avg_round: 2.96,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Caesar',
    app_rate: 2.14,
    rank: 6,
    avg_round: 2.9,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'anby-demara',
    char_three: 'Soukaku',
    app_rate: 1.79,
    rank: 7,
    avg_round: 2.74,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Seth',
    char_three: 'Rina',
    app_rate: 1.67,
    rank: 8,
    avg_round: 2.94,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Burnice',
    char_three: 'Caesar',
    app_rate: 1.55,
    rank: 9,
    avg_round: 2.98,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Burnice',
    char_three: 'Lucy',
    app_rate: 1.4,
    rank: 10,
    avg_round: 2.97,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 1.26,
    rank: 11,
    avg_round: 2.84,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Burnice',
    char_three: 'Rina',
    app_rate: 1.19,
    rank: 12,
    avg_round: 2.98,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Koleda',
    char_three: 'Soukaku',
    app_rate: 1.15,
    rank: 13,
    avg_round: 2.79,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'nicole-demara',
    char_three: 'Caesar',
    app_rate: 0.97,
    rank: 14,
    avg_round: 2.9,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.95,
    rank: 15,
    avg_round: 2.91,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Rina',
    char_three: 'Caesar',
    app_rate: 0.89,
    rank: 16,
    avg_round: 2.91,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Soukaku',
    char_three: 'Rina',
    app_rate: 0.74,
    rank: 17,
    avg_round: 2.54,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.68,
    rank: 18,
    avg_round: 2.71,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Burnice',
    char_three: 'Rina',
    app_rate: 0.64,
    rank: 19,
    avg_round: 2.93,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Rina',
    app_rate: 0.64,
    rank: 19,
    avg_round: 2.75,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Seth',
    char_three: 'Rina',
    app_rate: 0.58,
    rank: 21,
    avg_round: 2.75,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Koleda',
    char_three: 'nicole-demara',
    app_rate: 0.58,
    rank: 21,
    avg_round: 2.77,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'Qingyi',
    app_rate: 0.54,
    rank: 23,
    avg_round: 2.91,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.54,
    rank: 23,
    avg_round: 2.83,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Seth',
    char_three: 'Lucy',
    app_rate: 0.45,
    rank: 25,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.43,
    rank: 26,
    avg_round: 2.85,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'Seth',
    app_rate: 0.41,
    rank: 27,
    avg_round: 2.59,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'Rina',
    app_rate: 0.41,
    rank: 27,
    avg_round: 2.85,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Rina',
    char_three: 'Lucy',
    app_rate: 0.41,
    rank: 27,
    avg_round: 2.94,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Qingyi',
    char_three: 'Seth',
    app_rate: 0.39,
    rank: 30,
    avg_round: 2.95,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.39,
    rank: 30,
    avg_round: 2.77,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Seth',
    char_three: 'Caesar',
    app_rate: 0.35,
    rank: 32,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Seth',
    char_three: 'Caesar',
    app_rate: 0.33,
    rank: 33,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Burnice',
    char_three: 'Seth',
    app_rate: 0.33,
    rank: 33,
    avg_round: 2.87,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Piper',
    char_three: 'Lucy',
    app_rate: 0.33,
    rank: 33,
    avg_round: 2.81,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Soukaku',
    char_three: 'Seth',
    app_rate: 0.31,
    rank: 36,
    avg_round: 2.93,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.31,
    rank: 36,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Seth',
    char_three: 'Lucy',
    app_rate: 0.31,
    rank: 36,
    avg_round: 2.85,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.31,
    rank: 36,
    avg_round: 2.87,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Rina',
    char_three: 'Caesar',
    app_rate: 0.29,
    rank: 40,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.29,
    rank: 40,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.29,
    rank: 40,
    avg_round: 2.79,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.29,
    rank: 40,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Burnice',
    char_three: 'Lucy',
    app_rate: 0.29,
    rank: 40,
    avg_round: 2.86,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.29,
    rank: 40,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.29,
    rank: 40,
    avg_round: 2.79,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.27,
    rank: 47,
    avg_round: 2.89,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Seth',
    char_three: 'Rina',
    app_rate: 0.25,
    rank: 48,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.25,
    rank: 48,
    avg_round: 2.58,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'anby-demara',
    char_three: 'Seth',
    app_rate: 0.25,
    rank: 48,
    avg_round: 2.58,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Burnice',
    char_three: 'Seth',
    app_rate: 0.25,
    rank: 48,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Burnice',
    char_three: 'Lucy',
    app_rate: 0.25,
    rank: 48,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Yanagi',
    char_three: 'Qingyi',
    app_rate: 0.25,
    rank: 48,
    avg_round: 2.57,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'grace-howard',
    char_three: 'Rina',
    app_rate: 0.23,
    rank: 54,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.23,
    rank: 54,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Burnice',
    char_three: 'Lucy',
    app_rate: 0.23,
    rank: 54,
    avg_round: 2.9,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Piper',
    char_three: 'Caesar',
    app_rate: 0.23,
    rank: 54,
    avg_round: 2.88,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Qingyi',
    char_three: 'Caesar',
    app_rate: 0.23,
    rank: 54,
    avg_round: 2.8,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.21,
    rank: 59,
    avg_round: 2.7,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'Seth',
    app_rate: 0.21,
    rank: 59,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'anby-demara',
    app_rate: 0.19,
    rank: 61,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.19,
    rank: 61,
    avg_round: 2.86,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'grace-howard',
    char_three: 'Seth',
    app_rate: 0.16,
    rank: 63,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.16,
    rank: 63,
    avg_round: 2.83,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.16,
    rank: 63,
    avg_round: 2.8,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'grace-howard',
    char_three: 'Qingyi',
    app_rate: 0.16,
    rank: 63,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'anby-demara',
    char_three: 'Seth',
    app_rate: 0.16,
    rank: 63,
    avg_round: 2.4,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'anby-demara',
    char_three: 'Soukaku',
    app_rate: 0.16,
    rank: 63,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.16,
    rank: 63,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Qingyi',
    char_three: 'Seth',
    app_rate: 0.16,
    rank: 63,
    avg_round: 2.83,
    star_num: '4'
  },
  {
    char_one: 'Lycaon',
    char_two: 'Soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.14,
    rank: 71,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Qingyi',
    char_three: 'Soukaku',
    app_rate: 0.14,
    rank: 71,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Burnice',
    char_three: 'Qingyi',
    app_rate: 0.14,
    rank: 71,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Qingyi',
    char_three: 'Caesar',
    app_rate: 0.14,
    rank: 71,
    avg_round: 2.4,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Qingyi',
    char_three: 'Caesar',
    app_rate: 0.14,
    rank: 71,
    avg_round: 2.6,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Seth',
    char_three: 'nicole-demara',
    app_rate: 0.14,
    rank: 71,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Lycaon',
    char_three: 'Rina',
    app_rate: 0.14,
    rank: 71,
    avg_round: 2.83,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.14,
    rank: 71,
    avg_round: 2.71,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Seth',
    char_three: 'nicole-demara',
    app_rate: 0.14,
    rank: 71,
    avg_round: 2.71,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Soukaku',
    char_three: 'Lucy',
    app_rate: 0.12,
    rank: 80,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 0.12,
    rank: 80,
    avg_round: 2.33,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Burnice',
    char_three: 'Caesar',
    app_rate: 0.12,
    rank: 80,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.12,
    rank: 80,
    avg_round: 2.8,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.12,
    rank: 80,
    avg_round: 2.83,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Burnice',
    char_three: 'anby-demara',
    app_rate: 0.12,
    rank: 80,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'grace-howard',
    char_three: 'Soukaku',
    app_rate: 0.1,
    rank: 86,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Burnice',
    char_three: 'Lucy',
    app_rate: 0.1,
    rank: 86,
    avg_round: 2.75,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Burnice',
    char_three: 'Caesar',
    app_rate: 0.1,
    rank: 86,
    avg_round: 2.75,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Piper',
    char_three: 'Rina',
    app_rate: 0.1,
    rank: 86,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Burnice',
    char_three: 'Seth',
    app_rate: 0.1,
    rank: 86,
    avg_round: 2.75,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'grace-howard',
    char_three: 'Qingyi',
    app_rate: 0.1,
    rank: 86,
    avg_round: 2.8,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Seth',
    char_three: 'Rina',
    app_rate: 0.08,
    rank: 92,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Burnice',
    char_three: 'Qingyi',
    app_rate: 0.08,
    rank: 92,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Lucy',
    app_rate: 0.08,
    rank: 92,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Qingyi',
    char_three: 'Seth',
    app_rate: 0.08,
    rank: 92,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'Caesar',
    app_rate: 0.08,
    rank: 92,
    avg_round: 2.75,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'Rina',
    app_rate: 0.08,
    rank: 92,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Seth',
    char_three: 'Caesar',
    app_rate: 0.08,
    rank: 92,
    avg_round: 2.75,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'anby-demara',
    char_three: 'Caesar',
    app_rate: 0.08,
    rank: 92,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'grace-howard',
    char_three: 'Soukaku',
    app_rate: 0.08,
    rank: 92,
    avg_round: 2.75,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'jane-doe',
    char_three: 'Seth',
    app_rate: 0.08,
    rank: 92,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'grace-howard',
    char_three: 'Lycaon',
    app_rate: 0.08,
    rank: 92,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'anby-demara',
    char_three: 'Seth',
    app_rate: 0.08,
    rank: 92,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Seth',
    char_three: 'nicole-demara',
    app_rate: 0.08,
    rank: 92,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Qingyi',
    char_three: 'Caesar',
    app_rate: 0.08,
    rank: 92,
    avg_round: 2.75,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Ellen',
    char_three: 'Qingyi',
    app_rate: 0.08,
    rank: 92,
    avg_round: 2.33,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 0.08,
    rank: 92,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'grace-howard',
    char_three: 'Caesar',
    app_rate: 0.08,
    rank: 92,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.06,
    rank: 109,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'nicole-demara',
    char_three: 'Caesar',
    app_rate: 0.06,
    rank: 109,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'grace-howard',
    char_three: 'Caesar',
    app_rate: 0.06,
    rank: 109,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Koleda',
    char_three: 'Rina',
    app_rate: 0.06,
    rank: 109,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Burnice',
    char_three: 'Koleda',
    app_rate: 0.06,
    rank: 109,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Rina',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 109,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'Ben',
    app_rate: 0.06,
    rank: 109,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Qingyi',
    char_three: 'anby-demara',
    app_rate: 0.06,
    rank: 109,
    avg_round: 2.33,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 109,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'Koleda',
    app_rate: 0.06,
    rank: 109,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Rina',
    char_three: 'Lucy',
    app_rate: 0.06,
    rank: 109,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 109,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Qingyi',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 109,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'grace-howard',
    char_three: 'Qingyi',
    app_rate: 0.06,
    rank: 109,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Soukaku',
    char_three: 'Rina',
    app_rate: 0.06,
    rank: 109,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Koleda',
    char_three: 'Caesar',
    app_rate: 0.06,
    rank: 109,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Qingyi',
    char_three: 'Lycaon',
    app_rate: 0.06,
    rank: 109,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.06,
    rank: 109,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'anby-demara',
    char_three: 'Caesar',
    app_rate: 0.06,
    rank: 109,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 109,
    avg_round: 2.67,
    star_num: '4'
  },
  {
    char_one: 'Qingyi',
    char_two: 'Seth',
    char_three: 'Rina',
    app_rate: 0.06,
    rank: 109,
    avg_round: 2.33,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Burnice',
    char_three: 'Caesar',
    app_rate: 0.06,
    rank: 109,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Rina',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 109,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.06,
    rank: 109,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Burnice',
    char_three: 'anby-demara',
    app_rate: 0.06,
    rank: 109,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'grace-howard',
    char_three: 'anby-demara',
    app_rate: 0.06,
    rank: 109,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Burnice',
    char_three: 'Soukaku',
    app_rate: 0.06,
    rank: 109,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'Rina',
    app_rate: 0.06,
    rank: 109,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Yanagi',
    char_three: 'Soukaku',
    app_rate: 0.06,
    rank: 109,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 138,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.04,
    rank: 138,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'grace-howard',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 138,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 138,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'grace-howard',
    char_three: 'Burnice',
    app_rate: 0.04,
    rank: 138,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Koleda',
    char_three: 'Rina',
    app_rate: 0.04,
    rank: 138,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Burnice',
    char_three: 'anby-demara',
    app_rate: 0.04,
    rank: 138,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 138,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Koleda',
    char_three: 'Ben',
    app_rate: 0.04,
    rank: 138,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'grace-howard',
    char_three: 'anby-demara',
    app_rate: 0.04,
    rank: 138,
    avg_round: 1.5,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 138,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Qingyi',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 138,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'nicole-demara',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 138,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'Seth',
    app_rate: 0.04,
    rank: 138,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Qingyi',
    char_three: 'Seth',
    app_rate: 0.04,
    rank: 138,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'nicole-demara',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 138,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Lycaon',
    char_three: 'Rina',
    app_rate: 0.04,
    rank: 138,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'anby-demara',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 138,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 138,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Koleda',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 138,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Rina',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 138,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Lycaon',
    char_two: 'Soukaku',
    char_three: 'Rina',
    app_rate: 0.04,
    rank: 138,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 138,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Seth',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 138,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Rina',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 138,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'jane-doe',
    char_three: 'Rina',
    app_rate: 0.04,
    rank: 138,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'anby-demara',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 138,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Yanagi',
    char_three: 'Rina',
    app_rate: 0.04,
    rank: 138,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 138,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Burnice',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 138,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Burnice',
    char_three: 'Qingyi',
    app_rate: 0.04,
    rank: 138,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Lycaon',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 138,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Ben',
    app_rate: 0.04,
    rank: 138,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 138,
    avg_round: 1.5,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'jane-doe',
    char_three: 'Qingyi',
    app_rate: 0.04,
    rank: 138,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 138,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'Qingyi',
    char_three: 'Caesar',
    app_rate: 0.04,
    rank: 138,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 0.04,
    rank: 138,
    avg_round: 1.5,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'grace-howard',
    char_three: 'Rina',
    app_rate: 0.04,
    rank: 138,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'anby-demara',
    app_rate: 0.04,
    rank: 138,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Burnice',
    char_three: 'Koleda',
    app_rate: 0.04,
    rank: 138,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Burnice',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 138,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'grace-howard',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 138,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 138,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Piper',
    char_three: 'Qingyi',
    app_rate: 0.04,
    rank: 138,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'grace-howard',
    char_three: 'Koleda',
    app_rate: 0.04,
    rank: 138,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Soukaku',
    char_three: 'Seth',
    app_rate: 0.04,
    rank: 138,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Piper',
    char_three: 'nicole-demara',
    app_rate: 0.04,
    rank: 138,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Piper',
    char_three: 'anby-demara',
    app_rate: 0.04,
    rank: 138,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'jane-doe',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 138,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Yanagi',
    char_three: 'Rina',
    app_rate: 0.04,
    rank: 138,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'anby-demara',
    char_three: 'Lucy',
    app_rate: 0.04,
    rank: 138,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'grace-howard',
    char_three: 'Seth',
    app_rate: 0.04,
    rank: 138,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'anby-demara',
    char_two: 'Soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.02,
    rank: 191,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Seth',
    char_three: 'Lucy',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Koleda',
    char_three: 'nicole-demara',
    app_rate: 0.02,
    rank: 191,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Koleda',
    char_three: 'nicole-demara',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'anby-demara',
    char_two: 'Rina',
    char_three: 'Caesar',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Ellen',
    char_three: 'Burnice',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Seth',
    char_three: 'Rina',
    app_rate: 0.02,
    rank: 191,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Soukaku',
    char_three: 'Lucy',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Koleda',
    app_rate: 0.02,
    rank: 191,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'grace-howard',
    char_three: 'Lucy',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Lycaon',
    char_three: 'Rina',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'anby-demara',
    char_three: 'Lucy',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Lycaon',
    char_three: 'Rina',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Ben',
    char_three: 'Lucy',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Qingyi',
    char_three: 'Soukaku',
    app_rate: 0.02,
    rank: 191,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'grace-howard',
    char_three: 'Soukaku',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Burnice',
    char_three: 'Ben',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Qingyi',
    char_three: 'Seth',
    app_rate: 0.02,
    rank: 191,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Piper',
    char_three: 'nicole-demara',
    app_rate: 0.02,
    rank: 191,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Piper',
    char_three: 'anby-demara',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'anby-demara',
    char_two: 'nicole-demara',
    char_three: 'Caesar',
    app_rate: 0.02,
    rank: 191,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Burnice',
    char_three: 'Caesar',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Seth',
    char_three: 'nicole-demara',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.02,
    rank: 191,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'Soukaku',
    char_three: 'Lucy',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Qingyi',
    char_three: 'Caesar',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Rina',
    char_three: 'Caesar',
    app_rate: 0.02,
    rank: 191,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Burnice',
    char_three: 'Caesar',
    app_rate: 0.02,
    rank: 191,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Soukaku',
    char_three: 'Lucy',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'nicole-demara',
    char_three: 'Caesar',
    app_rate: 0.02,
    rank: 191,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'grace-howard',
    char_three: 'Koleda',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Seth',
    char_three: 'Rina',
    app_rate: 0.02,
    rank: 191,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Ben',
    char_three: 'nicole-demara',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Qingyi',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lycaon',
    char_three: 'Seth',
    app_rate: 0.02,
    rank: 191,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Qingyi',
    char_three: 'Soukaku',
    app_rate: 0.02,
    rank: 191,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'jane-doe',
    char_three: 'nicole-demara',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Soukaku',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'nicole-demara',
    char_three: 'Caesar',
    app_rate: 0.02,
    rank: 191,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.02,
    rank: 191,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Koleda',
    char_three: 'Soukaku',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Qingyi',
    char_three: 'Caesar',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'grace-howard',
    char_three: 'Koleda',
    app_rate: 0.02,
    rank: 191,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Ellen',
    char_three: 'Soukaku',
    app_rate: 0.02,
    rank: 191,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Lucy',
    char_three: 'Caesar',
    app_rate: 0.02,
    rank: 191,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Lycaon',
    char_three: 'Seth',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'Ben',
    char_three: 'Lucy',
    app_rate: 0.02,
    rank: 191,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Qingyi',
    char_two: 'Seth',
    char_three: 'Lucy',
    app_rate: 0.02,
    rank: 191,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Burnice',
    char_three: 'nicole-demara',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Burnice',
    char_three: 'Lycaon',
    app_rate: 0.02,
    rank: 191,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Burnice',
    char_three: 'Ben',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Ellen',
    char_three: 'Rina',
    app_rate: 0.02,
    rank: 191,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Qingyi',
    char_three: 'Ben',
    app_rate: 0.02,
    rank: 191,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'jane-doe',
    char_three: 'Seth',
    app_rate: 0.02,
    rank: 191,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Qingyi',
    char_three: 'anby-demara',
    app_rate: 0.02,
    rank: 191,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'nicole-demara',
    app_rate: 0.02,
    rank: 191,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Yanagi',
    char_three: 'anby-demara',
    app_rate: 0.02,
    rank: 191,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Qingyi',
    char_two: 'Rina',
    char_three: 'nicole-demara',
    app_rate: 0.02,
    rank: 191,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'anby-demara',
    char_three: 'Caesar',
    app_rate: 0.02,
    rank: 191,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'jane-doe',
    char_three: 'Soukaku',
    app_rate: 0.02,
    rank: 191,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Corin',
    char_three: 'Rina',
    app_rate: 0.02,
    rank: 191,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Qingyi',
    char_three: 'Lycaon',
    app_rate: 0.02,
    rank: 191,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Piper',
    char_three: 'Burnice',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Piper',
    char_three: 'Seth',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Burnice',
    char_three: 'Lucy',
    app_rate: 0.02,
    rank: 191,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Qingyi',
    char_three: 'Soukaku',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.02,
    rank: 191,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Koleda',
    char_three: 'Ben',
    app_rate: 0.02,
    rank: 191,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'Seth',
    char_three: 'Lucy',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Koleda',
    char_three: 'Rina',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Yanagi',
    char_three: 'nicole-demara',
    app_rate: 0.02,
    rank: 191,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Koleda',
    char_three: 'nicole-demara',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'grace-howard',
    char_three: 'anby-demara',
    app_rate: 0.02,
    rank: 191,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Qingyi',
    char_three: 'Lucy',
    app_rate: 0.02,
    rank: 191,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Nekomata',
    char_two: 'Piper',
    char_three: 'Caesar',
    app_rate: 0.02,
    rank: 191,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Ellen',
    char_three: 'Lycaon',
    app_rate: 0.02,
    rank: 191,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Qingyi',
    char_three: 'Koleda',
    app_rate: 0.02,
    rank: 191,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'anby-demara',
    char_three: 'Rina',
    app_rate: 0.02,
    rank: 191,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Yanagi',
    char_three: 'jane-doe',
    app_rate: 0.02,
    rank: 191,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Anton',
    char_three: 'Qingyi',
    app_rate: 0.02,
    rank: 191,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Burnice',
    char_three: 'Qingyi',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Corin',
    char_two: 'Lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.02,
    rank: 191,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Burnice',
    char_three: 'Soukaku',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Lycaon',
    char_three: 'Soukaku',
    app_rate: 0.02,
    rank: 191,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'anby-demara',
    char_three: 'Caesar',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'anby-demara',
    char_three: 'Seth',
    app_rate: 0.02,
    rank: 191,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Soukaku',
    char_three: 'Seth',
    app_rate: 0.02,
    rank: 191,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'Ellen',
    char_three: 'Soukaku',
    app_rate: 0.02,
    rank: 191,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Qingyi',
    char_three: 'Lycaon',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'nicole-demara',
    char_three: 'Caesar',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Koleda',
    char_three: 'Ben',
    app_rate: 0.02,
    rank: 191,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Anton',
    char_two: 'Ben',
    char_three: 'Caesar',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Piper',
    char_three: 'Soukaku',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Koleda',
    char_three: 'Seth',
    app_rate: 0.02,
    rank: 191,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Burnice',
    char_three: 'Rina',
    app_rate: 0.02,
    rank: 191,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'grace-howard',
    char_three: 'Seth',
    app_rate: 0.02,
    rank: 191,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'Caesar',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Anton',
    char_three: 'Rina',
    app_rate: 0.02,
    rank: 191,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'nicole-demara',
    char_three: 'Caesar',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Burnice',
    char_two: 'Qingyi',
    char_three: 'Seth',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'jane-doe',
    char_three: 'Caesar',
    app_rate: 0.02,
    rank: 191,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Piper',
    char_two: 'Koleda',
    char_three: 'Lucy',
    app_rate: 0.02,
    rank: 191,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Qingyi',
    char_two: 'Soukaku',
    char_three: 'Rina',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Piper',
    char_three: 'Koleda',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'grace-howard',
    char_three: 'Rina',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Rina',
    char_three: 'nicole-demara',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Qingyi',
    char_two: 'Seth',
    char_three: 'nicole-demara',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Qingyi',
    char_three: 'anby-demara',
    app_rate: 0.02,
    rank: 191,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'anby-demara',
    char_three: 'Soukaku',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Koleda',
    char_three: 'Rina',
    app_rate: 0.02,
    rank: 191,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'anby-demara',
    char_three: 'Soukaku',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'Yanagi',
    char_three: 'anby-demara',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Corin',
    char_three: 'Soukaku',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'Qingyi',
    char_three: 'Ben',
    app_rate: 0.02,
    rank: 191,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Ellen',
    char_two: 'Yanagi',
    char_three: 'Lycaon',
    app_rate: 0.02,
    rank: 191,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Yanagi',
    char_two: 'Koleda',
    char_three: 'Ben',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'Qingyi',
    char_three: 'Caesar',
    app_rate: 0.02,
    rank: 191,
    avg_round: 3,
    star_num: '4'
  }
];
